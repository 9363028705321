import { Component, OnInit } from "@angular/core";
import { FuseConfigService } from "@fuse/services/config.service";
import { FormBuilder } from "@angular/forms";

@Component({
    selector: "app-web",
    templateUrl: "./web.component.html",
    styleUrls: ["./web.component.scss"],
})
export class WebComponent implements OnInit {
    
    constructor( )
    {}
    ngOnInit() {}
}
