export const locale = {
    lang: 'hi',
    data: {
        'NAV': {
            'APPLICATIONS': 'अप्लिकेशंस',
            'HOME'        : {
                'TITLE': 'होम',
                'BADGE': '25'
            },
            'RESULT'        : {
                'TITLE': 'परिणाम',
                'BADGE': '0'
            },
            'ATTENDANCE'        : {
                'TITLE': 'उपस्थिति',
                'BADGE': '0'
            },
            'ADMISSION'        : {
                'TITLE': 'प्रवेश',
                'BADGE': '10'
            },
            'TIMETABLE'        : {
                'TITLE': 'समय सारणी',
                'BADGE': '10'
            }
        }
    }
};
