import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome/welcome.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { HeaderComponent } from './web-shared/header/header.component';
import { WebComponent } from './web.component';
import { AboutComponent } from './about/about.component';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';
import { SampleContentComponent } from './web-shared/sample-content/sample-content.component';
import { ExtraCurricularComponent } from './extra-curricular/extra-curricular.component';
import { OurTeamComponent } from './our-team/our-team.component';
import {MatTabsModule} from '@angular/material/tabs';
import { FaqModule } from '../faq/faq.module';
import { FaqComponent } from '../faq/faq.component';
import { ContactComponent } from './contact/contact.component';


@NgModule({
  declarations: [WelcomeComponent, HeaderComponent, WebComponent,
     AboutComponent, SampleContentComponent, ExtraCurricularComponent,
      OurTeamComponent,
      ContactComponent],
  imports     : [
    RouterModule,
    TranslateModule,
    FuseSharedModule,
    FooterModule,
    MatMenuModule,
    MatRippleModule,
    MatTabsModule,
    FaqModule
],
  exports:[
    WebComponent
  ]
})
export class WebModule { }
