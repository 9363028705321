import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sample-content',
  templateUrl: './sample-content.component.html',
  styleUrls: ['./sample-content.component.scss']
})
export class SampleContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
