export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'HOME'        : {
                'TITLE': 'Home',
                'BADGE': '25'
            },
            'RESULT'        : {
                'TITLE': 'Result',
                'BADGE': '0'
            },
            'ATTENDANCE'        : {
                'TITLE': 'Attendance',
                'BADGE': '0'
            },
            'ADMISSION'        : {
                'TITLE': 'Admission',
                'BADGE': '10'
            },
            'TIMETABLE'        : {
                'TITLE': 'Time Table',
                'BADGE': '10'
            }
        }
    }
};
