import { Component, OnInit } from "@angular/core";


@Component({
    selector: "web-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    layouts: any;
    constructor() {
    }

    ngOnInit() {
    }
}
