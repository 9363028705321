import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'home',
                title    : 'Home',
                translate: 'NAV.HOME.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/',
                exactMatch: true,
                badge    : {
                    title    : '25',
                    translate: 'NAV.HOME.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'results',
                title    : 'Results',
                translate: 'NAV.RESULT.TITLE',
                type     : 'item',
                icon     : 'all_out',
                url      : '/apps/results',
                badge    : {
                    title    : '2',
                    translate: 'NAV.RESULT.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'attendance',
                title    : 'Attendance',
                translate: 'NAV.ATTENDANCE.TITLE',
                type     : 'item',
                icon     : 'all_out',
                url      : '/apps/attendance',
                badge    : {
                    title    : '10',
                    translate: 'NAV.ATTENDANCE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'admission',
                title    : 'Admission',
                translate: 'NAV.ADMISSION.TITLE',
                type     : 'item',
                icon     : 'all_out',
                url      : '/apps/admission',
                badge    : {
                    title    : '10',
                    translate: 'NAV.ADMISSION.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'timetable',
                title    : 'Time Table',
                translate: 'NAV.TIMETABLE.TITLE',
                type     : 'item',
                icon     : 'all_out',
                url      : '/apps/timetable',
                badge    : {
                    title    : '10',
                    translate: 'NAV.TIMETABLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            }
        ]
    }
];
