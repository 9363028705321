import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { WebModule } from "./main/pages/web/web.module";
import { WebComponent } from "./main/pages/web/web.component";
import { WelcomeComponent } from './main/pages/web/welcome/welcome.component';
import { AboutComponent } from './main/pages/web/about/about.component';
import { ExtraCurricularComponent } from './main/pages/web/extra-curricular/extra-curricular.component';
import { OurTeamComponent } from './main/pages/web/our-team/our-team.component';
import { ContactComponent } from './main/pages/web/contact/contact.component';
import { HashLocationStrategy, LocationStrategy } from "@angular/common";


const appRoutes: Routes = [
    {
        path: "",
        component: WebComponent,
        children: [
            { path: "", component:WelcomeComponent},
            { path: "about", component:AboutComponent},
            { path: "extra", component:ExtraCurricularComponent},
            { path: "team", component:OurTeamComponent},
            { path: "contact", component:ContactComponent}
           
         ],
    },
    {
        path        : 'apps',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path        : 'pages',
        loadChildren: './main/pages/pages.module#PagesModule'
    },
   
    {
        path: "**",
        redirectTo: "",
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        WebModule
    ],
    providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
    bootstrap: [AppComponent],
})
export class AppModule {}
